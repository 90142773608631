@font-face {
font-family: 'nanumSquareRound';
src: url(/_next/static/media/14764d7d7d83411e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'nanumSquareRound';
src: url(/_next/static/media/0f315e740a11ec36-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'nanumSquareRound';
src: url(/_next/static/media/e45dce43c85c1624-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'nanumSquareRound Fallback';src: local("Arial");ascent-override: 82.18%;descent-override: 24.65%;line-gap-override: 2.90%;size-adjust: 103.43%
}.__className_99474a {font-family: 'nanumSquareRound', 'nanumSquareRound Fallback'
}

